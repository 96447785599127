/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

/*
	Style
*/
.fc-timegrid-slots {
	display: none;
}

.fc-timegrid-col {
	display: none;
}

.fc-timegrid-axis .fc-scrollgrid-shrink {
	display: none;
}

.fc-timeGridWeek-view .fc-scrollgrid-section-body,
.fc-timeGridWeek-view .fc-scroller-harness,
.fc-timeGridWeek-view .fc-scroller,
.fc-daygrid-body {
	height: 100%;
}

.fc-scrollgrid-sync-table {
	height: 100% !important;
}

.nri,.promotion {
	background-color: #ea5455 !important;
	color: black !important;
}

.mkt, .brand_ad {
	background-color: #6fd19b !important;
	color: black !important;
}
.partner_ad {
	background-color: #18c766 !important;
	color: black !important;
}

.virgule, .jingle {
	color: black !important;
	background-color: #7367f0 !important;
}
.information {
	color: black !important;
	background-color: #5da3ff !important;
}



.multiline {
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.media {
	border: 1px dashed gray;
	padding: 0.5rem 1rem;
	margin-bottom: .5rem;
	background-color: #73e673;
	cursor: "move";
	margin: 0.5em;
	padding: 0.8em;
	color: black;
}

.group {
	border: 1px dashed black;
	padding: 0.5rem 1rem;
	margin-bottom: .5rem;
	background-color: #738fe6;
	cursor: "move";
	margin: 0.5em;
	padding: 0.5em;
	color: black;
}

.playlist {
	border: 1px dashed gray;
	padding: 0.5rem 1rem;
	margin-bottom: .5rem;
	background-color: white;
	cursor: "move";
	margin: 0.5em;
	padding: 0.5em;
	color: black;
}

.downloader {
	border: 1px dashed #d98ef0;
	padding: 0.5rem 1rem;
	margin-bottom: .5rem;
	background-color: #faf0fe;
	cursor: "move";
	margin: 0.5em;
	padding: 0.5em;
	border-color: #d98ef0;
	color: black;
}

.hot {
	padding: 0.5rem 1rem;
	margin-bottom: .5rem;
	cursor: "move";
	margin: 0.5em;
	padding: 0.5em;
	border-color: #d98ef0;
	color: black;
	background-color: #ffc833;
	border-color: #ffe680;
}

.user_name {
	margin-left: 10px;
	margin-right: 10px;
	text-transform: none;
	color: black;
	text-transform: none;
}

.black table {
	color: black;
}

a .text-body {
	color: black;
}

li {
	list-style-type: none;
}

.react-dataTable > .react-dataTable {
	overflow: auto;
}

.tab-content {
	overflow-y: visible !important;
}

.column-action .dropdown {
	overflow: inherit !important;
	z-index: 99;
}

.react-bs-table table td, .react-bs-table table th {
	overflow: inherit !important;
}

.react-bs-container-body {
	overflow: inherit !important;
}

.hkMDrI {
	overflow: inherit !important;
}

.bg-red {
	background-color: red;
}

.rt-td {
	white-space: normal !important
}


#switch-danger:not(:checked) {
	background-color: #ff0000;
}

#switch-success-group:checked {
	border-color: #128a47;
	background-color: #128a47;
}

.leaflet-container {
	height: 100%;
}


.brand-logo {
	width: 36px;
	height: 36px;
}

.app-user-list {
	height:100%;
	min-width: 1099px;
}

button.small.btn {
	font-size: xx-small !important;
}

/*--------------------------------*/
/*        EDIT DE SEB  - V3       */
/*--------------------------------*/

/* ------- GLOBAL EDIT --------*/

	/* ------- dropdown menus ------- */
	.mb-1 > div > div {
		background-color: transparent !important;
		backdrop-filter:blur(5px) invert(5%);
		.select__option {
            color:black;
            background-color:rgba(255, 255, 255, 0.5);
            /*text-shadow:0px 0px 3px white;*/
        }
        .select__option--is-focused{
            font-weight:bolder;
            background-color:rgba(115, 103, 240, .12)!important;
            text-shadow:1px 0px 0px white,0px 1px 0px white,
                -1px 0px 0px white,0px -1px 0px white
        }
	}
	.dark-layout{
		.mb-1.col-sm-2 * {
			color: white !important;
			.css-26l3qy-menu{
				background-color: transparent;
				div *:hover{
				}
			}
		}
	}

	/*------- checks -----------*/
	[dir] .dark-layout .form-switch .form-check-input:not(:checked):not(:focus) {
		background-image: url("data:image/svg+xml,%3csvg width='26px' height='26px' viewBox='0 0 26 27' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cdefs%3e%3ccircle id='path-1' cx='8' cy='8' r='8'%3e%3c/circle%3e%3cfilter x='-40.6%' y='-21.9%' width='168.8%' height='168.8%' filterUnits='objectBoundingBox' id='filter-2'%3e%3cfeOffset dx='-1' dy='2' in='SourceAlpha' result='shadowOffsetOuter1'%3e%3c/feOffset%3e%3cfeGaussianBlur stdDeviation='1.5' in='shadowOffsetOuter1' result='shadowBlurOuter1'%3e%3c/feGaussianBlur%3e%3cfeColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0' type='matrix' in='shadowBlurOuter1'%3e%3c/feColorMatrix%3e%3c/filter%3e%3c/defs%3e%3cg id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='switches-dot' transform='translate%285.000000, 5.000000%29' fill-rule='nonzero'%3e%3cg id='Oval'%3e%3cuse fill='black' fill-opacity='1' filter='url%28%23filter-2%29' xlink:href='%23path-1'%3e%3c/use%3e%3cuse fill='%23fff' xlink:href='%23path-1'%3e%3c/use%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
	}

.rdt_ExpanderRow{
	background-color: transparent !important;
	
	.table tbody tr:nth-child(2n-1){
		background-color: rgba(255,255, 255, 0.02);
	}
}

.dark-layout .card {
    box-shadow: none;
}

.text-truncate{
	white-space: inherit;
}
.rdt_TableCell{
    -webkit-box-flex: 1;
    flex: 3 0 0px;
}

.nav-pills .nav-link{
	border-radius: 10px 10px 0 0 !important;
	margin-bottom:-22px;
}
.nav{
	row-gap: 24px;
}

.overflow-hidden {
	.react-dataTable:first-child, .col-12 .tab-content .tab-pane.active{
		height:inherit;
        border-top:#7266ef 2px solid;
        .react-dataTable{
            border-top:none;
        }
	}
    .col-12 .tab-content .card:first-child{
        padding-top:1em;
    }
	> .row{
		width: 100%;
		margin: 0 auto !important;
	}
}

.app-user-list {
    .col-12 {
        .card{
			margin-bottom:0;
		}
        .card-header{
            padding:0;
        }
    }
}

/* ------- TOP MENU --------*/

.container-xxl {
    max-width: 100%;
}


/* ------- MAIN MENU --------*/

.main-menu {
    .navigation{
        background-color: transparent !important;
		.menu-content{
			background-color: transparent !important;
		}
    }
    .navigation-header{
        font-weight:boldest;
        padding-top:2em;
    }

}

.main-menu.menu-light {
    background-color: #161d31 !important;
    box-shadow: none !important;
    color: #d0d2d6 !important;
    .navigation-header{
        color:#d0d2d6 !important;
    }
    svg{
        stroke:#d0d2d6;
    }
    .menu-item,.menu-title{
        color:#d0d2d6;
    }
	.navigation{
		.main-menu-content .navigation-main .nav-item.open>a, 
		>li.sidebar-group-active>a,
		>li.open:not(.menu-item-closing)>a {
			background-color: #262c3d!important;
		}
	}

}

.main-menu.menu-dark {
	background-color: #f8f8f8 !important;
	box-shadow: none !important;
	color: #161d31 !important;

	.navigation-header{
		color:#161d31 !important;
	}
	.nav-item:not(.active){
		svg{
			stroke:#161d31;
		}
		.menu-item,.menu-title{
			color:#161d31;
		}
	}
	.navigation{
		.main-menu-content .navigation-main .nav-item.open>a, 
		>li.sidebar-group-active>a,
		>li.open:not(.menu-item-closing)>a {
			background-color: #e6e6e6 !important;
		}
	}
}
/* ------- MEDIA PLANNER --------*/
svg.ficon:hover {
	fill: none;
	stroke-width: 3;
	stroke: #7367f0;
}
svg.ficon{
	  cursor:pointer;
}
.over{
	.card-transaction.card {
	  background-color:white !important;
	  color:#6e6b7b;
	}
	.btn{
	  color:#6e6b7b;
	}
	.float-end{
		color:#6e6b7b!important;
	  }
}
.fc-scrollgrid-section.fc-scrollgrid-section-body {
	min-height: 20px;
	height: 100%;
}
.ficon {

}
.ficon:hover {
  stroke-width: 5;
  filter:drop-shadow(0 0 10px #c3befa);
}
.ficon.success {
  stroke: green;
  stroke-width: 3;
}
.ficon.success:hover {
  stroke: green;
  stroke-width: 5;
  filter:drop-shadow(0 0 10px lightgreen)
}

.scheduler-view{
    .event-container{
        min-height: 44px;
        max-height: 100px;
        height: fit-content!important;
        margin: 0.2em 0;
    }
    .timeline-event{
        position: relative;
        display: block;
		color:black;
    }
	.scheduler-bg > table.scheduler-bg-table{
        height:100%;
        position:relative;
        tr{
            border-bottom:hidden!important;
            min-height: 44px;
            max-height: 100px;
            height: -webkit-fill-available!important;
        }
    }
}
.media-planner{
    .profile-image-wrapper {
        img {
            filter: invert(1);
        }
    }
}
.dark-layout{
    .media-planner{
        .profile-image-wrapper {
            img {
                filter: invert(0);
            }
        }
    }
	.sc-jrQzAO{
        .rdt_TableCell{
            color: #000000!important;
        }
		svg {
            stroke: #000000!important;
        }
    }
}

input#search-invoice {min-width:7em}

.invoice-list-table-header{
	.row {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		flex-direction: row;
		align-content: center;
	}
	.d-flex.align-items-center.p-0{
		min-width: 9em;
		max-width: 17em;
		width: fit-content;
		padding:.7em;
	}
	.d-flex.align-items-sm-center{
		min-width: 20em;
		max-width: 41em;
		width: fit-content;
		align-content: stretch;
		justify-content:flex-start!important;
	}
}

	
/*--- DARK  ---*/
.dark-layout{
	.over{
		.card-transaction.card {
			background-color:#283046 !important;
			color:#b4b7bd;
		}
		.btn{
			color:#b4b7bd;
		}
		.float-end{
			color:#7367f0!important;
		}
	}
}



/* ------- RECKON --------*/
#site-select {
    min-width: 180px;
}

.cameras{
	.card-body>.row {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		.card{
			width:100%;
		}
		.col-md-6.col-xl-6 {
			width:100%;
		} 
		.col-md-8.col-xl-8, .col-md-4.col-xl-4{
			width:50%;
		}
	
		.rv-xy-plot.rv-radar-chart {
			display: flex;
			justify-content: center;
		}
		.rv-discrete-color-legend-item .horizontal {
			display: inline-block;
			margin-right: 10px; /* Adjust spacing as needed */
		}
	
		.horizontal {
			display: inline-block;
			margin-right: 1.8em; /* Adjust spacing as needed */
	
		}		  
	}
    .rv-xy-plot{
		overflow-x: auto;
		overflow-y: hidden;
		width: 100% !important;
		background-color: transparent !important;
		svg.rv-xy-plot__inner {
			background-color: transparent !important;
			.rv-xy-plot__axis--vertical{
				transform: translate(2em, 0);
			}
		}
	}
	.legend {
		display: flex;
	    justify-content: center;
	    align-items: center;
	    color: #000;
	    font-size: 16px;
		background-color: transparent !important;

		.rv-discrete-color-legend-item__color{
			height: 0.9em;
			 margin-right: 0.2em;
		}
		.rv-discrete-color-legend-item__color__path{
			stroke-width: 20;
		}
	}

	.col-md-6.col-lg-6.col-xl-6 > img{
		width: auto;
	}

}
.dark-layout{
	.cameras{
		.mb1{
			.svg{
				stroke:white;
			}
		}
		.rv-xy-plot{
			svg.rv-xy-plot__inner {
				text {
					fill: #d0d2d6 !important;
				}
			}
		}
		.legend {
			background-color: #283046 !important;
			color:#d0d2d6 !important;
		}
	}
}


/* ------- PLAYLIST --------*/

.group.slot_disabled {
    background:repeating-linear-gradient(-45deg,#c6c9d6,#c6c9d6 20px,
                                         #a5afcc 20px,#a5afcc 40px);
    color: #495371;
    font-style:italic;
    font-size:smaller;
    > .media {
        background:repeating-linear-gradient(-45deg,#c8d6c6,#c8d6c6 20px,
                                         #a5cca6 20px,#a5cca6 40px);
        color: #4b7149;
    }
}
.media.slot_disabled {
    background:repeating-linear-gradient(-45deg,#c8d6c6,#c8d6c6 20px,
                                         #a5cca6 20px,#a5cca6 40px);
    color: #4b7149;
}

/* ------- CONTENT CREATOR --------*/

.content-medias {
    max-height:calc(100vh - 7.75rem - 5.35rem); 
    >.row{
        height: calc(100% - 3rem - 1.5rem - 1.714rem - 300px);
        .col-lg-7{
            height: -webkit-fill-available;      
        }
    }
    .overflow-hidden {
        .react-dataTable{
            height:inherit;
        }
    }
	.col-lg-7 {
		.row:first-child .col-lg-12 img {
			height:auto;
			max-height: calc(100vh - 7.75rem - 5.35rem - 3rem - 1.5rem - 1.714rem - 50px);
			max-width:100%;
			width:auto;
		}
		.row:nth-child(2) .col-lg-12 img {
			max-height: 11rem;
			max-width: 43%;
			width:auto !important;
			height:auto;
		}
	}
}
.content-creator, .content-creator-edit{
	height:calc(100vh - 11.1rem);
	.card:nth-child(2) {
		height: calc(100% - 9.5rem);
        .card-body {
			height:100%;
			> .row {
	    		height: 100%;      
	    	}
    	}
    	.col-lg-4{
    		height: 100%;
    		width: 35%!important;
    	}	
    	.col-lg-8{
    		max-height:100%;
    		width: 65%!important;
    		overflow-y: scroll;
    		box-shadow: none !important;
    	}
    	.col-lg-4, .col-lg-8 {
    		max-width: 1920px !important;
    	}
        .image-with-zones {
            >img{
                width:100%;
                height:100%;
            }
            .react-draggable{
                overflow:auto;
                display:flex;
                flex-wrap: nowrap;
                align-content: flex-start;
                flex-direction: column-reverse;
                justify-content: flex-end !important;
				.btn{
					margin:0 10px 10px 0;
				}
            }
        }
	}
	.css-b62m3t-container{
		z-index:20;
	}
	.react-select-container.css-b62m3t-container{
		z-index:19;
	}
	#x-spreadsheet-demo {
		height: calc(100% - 7em);
		z-index:1;
    	.x-spreadsheet {
    		height: 100%;
    	}
    	.x-spreadsheet-sheet {
    		height: calc(100% - 5.5em);
    	}
    	.x-spreadsheet {
    		overflow: hidden;
    	}
    	.x-spreadsheet-sheet, .x-spreadsheet-toolbar {
    		width:inherit !important;
    	}
    	.x-spreadsheet-scrollbar.horizontal {
    		width: 100% !important;
    	}
    	
    	.x-spreadsheet-scrollbar.vertical {
    		height: 100% !important;
    	}
    	
    	.x-spreadsheet-toolbar, .x-spreadsheet-sheet, .x-spreadsheet {
    		width: 100%;
    	}
    }
}


/* DARK LAYOUT*/
.dark-layout{
	.cameras{
		.rv-xy-plot{
			svg.rv-xy-plot__inner {
				background-color: #283046 !important;
				
				text {
					color: #d0d2d6 !important;
					background-color:red;
				}
			}
		}
		.legend {
			background-color: #283046 !important;
			color:#d0d2d6 !important;
		}


	}

}

.douze{
		
	background-color:#2530ef;
}
.dixsept{

	background-color:#efa026;
}
.trentequatre{

	background-color:#24dba6;

}
.trentecinque{

	background-color:#da24eb;
}

.cinquante{

	background-color:#a4db4c;

}
.pl{
	background-color: #2530a6;

}
.vl{

	background-color: #e87c1e;

}

.roues{

	background-color: #24dba6;
}

/* ------- MONITORING --------*/

.mb-2.card{
	> img {
	max-height: 62vh;
	max-width: 100%;
    width: fit-content;
	margin: 0 auto;
	}
}
